var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticStyle:{"background-color":"#FAFAFA"},attrs:{"icon":"mdi-google","title":"Google My Business Locations","color":"secondary"}},[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"success","x-large":"","dark":"","href":_vm.getDownloadUrl()}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-file-excel ")]),_vm._v(" Download CSV ")],1)],1)],1),_vm._l((_vm.items),function(profile,i){return [_c('v-row',{key:Math.floor(Math.random() * (5000 - 1 + 1)) + 1,attrs:{"dense":""}},[_c('h2',[_vm._v(" "+_vm._s(profile.location)+" ")])]),_c('v-row',{key:Math.floor(Math.random() * (5000 - 1 + 1)) + 1,attrs:{"dense":""}},[_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":profile.datum,"items-per-page":50,"sort-by":['name']},scopedSlots:_vm._u([{key:"item.direct_searches",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.discovery_searches",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.total_searches",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.search_views",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.map_views",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.total_views",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.website_actions",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.phone_actions",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.direction_actions",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}},{key:"item.total_actions",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatNumber(value))+" ")]}}],null,true)})],1)],1)],1)]})],2),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.showErrorDialog = false}},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_c('error-message',{attrs:{"error":_vm.error},on:{"closeErrorDialog":_vm.closeErrorDialog}})],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"#FF5E40","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.noPermissions),callback:function ($$v) {_vm.noPermissions=$$v},expression:"noPermissions"}},[_c('v-card',{attrs:{"color":"warning","dark":""}},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" You do not have permissions to access this feature. Contact Dealer Insights support for further assistance. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.goHome()}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }