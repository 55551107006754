export default {
  getErrorMessage (error) {
    var errorMessage
    const defaultMessage = 'Network Error. Check your internet connection.'
    if (error.response === undefined) {
      errorMessage = defaultMessage
    }
    else {
      errorMessage = error.response.statusText
      if (!errorMessage) {
        errorMessage = defaultMessage
      }
    }
    return errorMessage
  },
}
