<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-google"
      title="Google My Business Locations"
      color="secondary"
      style="background-color: #FAFAFA"
    >
      <v-row>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-btn
            color="success"
            x-large
            dark
            :href="getDownloadUrl()"
          >
            <v-icon
              color="white"
            >
              mdi-file-excel
            </v-icon>
            Download CSV
          </v-btn>
        </v-col>
      </v-row>
      <template v-for="(profile, i) in items">
        <v-row dense :key="Math.floor(Math.random() * (5000 - 1 + 1)) + 1">
          <h2>
            {{ profile.location}}
          </h2>
        </v-row>
        <v-row dense :key="Math.floor(Math.random() * (5000 - 1 + 1)) + 1">
          <v-col>
            <v-card>
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="profile.datum"
                :items-per-page="50"
                :sort-by="['name']"
              >
                <template #item.direct_searches="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.discovery_searches="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.total_searches="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.search_views="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.map_views="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.total_views="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.website_actions="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.phone_actions="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.direction_actions="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #item.total_actions="{value}">
                  {{ formatNumber(value) }}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </base-material-card>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noPermissions"
      persistent
      width="600"
    >
      <v-card
        color="warning"
        dark
      >
        <v-alert
          type="warning"
        >
          You do not have permissions to access this feature. Contact Dealer Insights support for further assistance.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="goHome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import GmbService from '@/services/GmbService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'GmbAllProfiles',
    components: {
      ErrorMessage,
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
    },
    mixins: [formatterMixin, titleMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        items: [],
        loading: true,
        error: '',
        expanded: [],
        unreadMessages: [],
        noPermissions: false,
        headers: [
          { text: 'Date', value: 'pretty_date_range'},
          { text: 'Direct Searches', value: 'direct_searches', align: 'center' },
          { text: 'Discovery Searches', value: 'discovery_searches', align: 'center' },
          { text: 'Total Searches', value: 'total_searches', align: 'center' },
          { text: 'Search Views', value: 'search_views', align: 'center' },
          { text: 'Map Views', value: 'map_views', align: 'center' },
          { text: 'Total Views', value: 'total_views', align: 'center' },
          { text: 'Website Actions', value: 'website_actions', align: 'center' },
          { text: 'Phone Actions', value: 'phone_actions', align: 'center' },
          { text: 'Directions Actions', value: 'direction_actions', align: 'center' },
          { text: 'Total Actions', value: 'total_actions', align: 'center' },
          { text: 'Rating', value: 'rating', align: 'center' },
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId', 'accountName', 'permissions']),
    },
    watch: {
      accountId () {
        this.loadProfiles()
      },
    },
    created () {
      // if (!this.permissions.includes('chatovate')) {
      //   this.noPermissions = true
      // }
      this.loadProfiles()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadProfiles: function () {
        this.loadingMessage = 'Loading Profiles'
        this.items = []
        this.loading = true
        GmbService.getAllProfiles()
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      goHome: function () {
        this.$router.push({ name: 'Home' })
      },
      getDownloadUrl: function () {
        return "https://dealer-insights-api.herokuapp.com/gmb/export_profile_datum?aid=" + this.accountId
      }
    },
  }
</script>
