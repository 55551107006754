<template>
  <v-card class="pl-2 pr-2">
    <v-card-title class="headline">
      Something Went Wrong!
    </v-card-title>
    <v-row dense>
      <v-col>
        <div class="pt-9">
          <v-alert
            color="#FFB001"
            dark
            icon="mdi-alert-decagram"
            border="left"
            prominent
          >
            <div class="display-1">
              Well, this is embarrassing. We're having a bit of trouble making things work.
            </div>
            <div class="mt-2 display-1">
              The problem that was reported was
              <span class="font-weight-black display-1">
                {{ error }}
              </span>
            </div>
          </v-alert>
        </div>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer />
      <v-btn
        :color="getPrimaryColor()"
        @click="closeErrorDialog()"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'ErrorMessage',
    props: {
      error: {
        type: String,
        default: () => (''),
      },
      app: {
        type: String,
        default: () => ('contact'),
      },
    },
    data: () => ({
      showReleaseDialog: false,
    }),
    computed: {
      ...mapGetters([]),
    },
    watch: {
    },
    created () {
    },
    methods: {
      closeErrorDialog: function () {
        this.$emit('closeErrorDialog')
      },
      getPrimaryColor: function () {
        if (this.app === 'contact') {
          return '#90C74C'
        }
        return '#2979FF'
      },
    },
  }
</script>
